@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

.demo
  max-width $max_width

.default
  padding 0 $padding
  margin 0 auto

  & .inner
    padding 40px 48px

    +pc()
      padding 60px 100px

.popup
  & .inner
    row-gap 20px

    +pc()
      row-gap 42px

  & .che
    max-width 100px

    +pc()
      max-width 100%

.inner
  justify-content center
  gap 42px 33px
  background #C3D4F3
  border-radius 20px
  max-width 1240px



.title
  grid-area title
  text-align center
  margin-bottom -13px

.subtitle
  grid-area subtitle
  text-align center
  font-size 20px
  line-height 1.2

  +pc()
    font-size 28px
    margin-bottom 70px

.trial
  grid-area trial
  text-align center

.che
  grid-area che
  display block
  max-width 100%
  height auto
  margin 0 auto

  +pc()
    margin 0

.free
  grid-area free
  text-align center

.button
  grid-area button
  text-align center

.sectionTitle
  font-weight 900
  font-size 20px
  line-height 1.1
  letter-spacing -0.05em
  margin 0 0 10px

  +pc()
    font-size 28px

.descr
  font-size 16px

.promo__block__wrapper
  margin-top 35px
  z-index 99999
  justify-content center
  +pc()
    display flex
    margin-top 370px

.promo__block
  border-radius 25px
  padding 40px

  font-weight 600
  z-index 99990
  margin-top 5px
  height: fit-content
  line-height: 30px
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5)
  background-color: #ffffff
  text-align: center
  font-size: 46px
  +pc()
    width: fit-content
    height: fit-content
.promo__block__center
  z-index 99999
  border-radius 25px
  padding 40px

  height: fit-content
  margin-top 5px
  line-height: 30px
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5)
  background-color: #ffffff
  text-align: center
  font-size: 46px
  +pc()
    width: fit-content
    height: fit-content
    margin-top: 45px
    margin-left: -25px
    margin-right: -25px


.image
  width 177px

  +pc()
    width 327px
    margin-top -527px

.wrapper
  justify-content center
  align-items center
  text-align center

