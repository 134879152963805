@import '../../../../styles/mixins.styl'
.quest__element
  background-color white
  width 286px
  height 280px
.questbuy__wrapper
  background #FFECCF
  border-radius 20px
  box-sizing border-box
  padding 20px
  margin 0 auto


.archiveTariff
  margin-bottom 30px

.subscription
  display flex
  flex-direction column
  gap 30px 20px

  +pc()
    flex-direction row

.currentTariff
  display grid
  grid-template auto 1fr \/ 1fr
  grid-auto-columns 1fr
  grid-template-areas 'title'
  flex 1 2 100%

  +pc()
    padding-right 20px

.tariffs
  display grid
  grid-template repeat(3, auto) \/ 1fr
  gap 10px 20px
  flex 1 1 100%
  width 100%

  +pc()
    grid-template 1fr \/ repeat(3, 1fr)
    row-gap 0

.otherTariffs
  grid-template-areas 'title'

  +pc()
    grid-template auto 1fr \/ repeat(2, 1fr)
    grid-template-areas 'title title'

.title
  grid-area title
  font-weight 900
  font-size 18px
  line-height 1.2
  letter-spacing -0.05em
  margin 0 0 23px

  +pc()
    font-size 24px

.logo
  max-width 100%
  height auto
  margin-top 30px

.recurrent
  margin 30px 0


.items
  margin-top 20px
  display grid
  grid-template-columns 1fr 1fr 1fr

