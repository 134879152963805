@import '../../../../../styles/mixins.styl'

.title
  font-weight 900
  font-size 18px
  line-height 1.2
  letter-spacing -0.05em
  margin 0

  +pc()
    font-size 20px
