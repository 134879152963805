@import '../../../../styles/mixins.styl'

.childEdit
  display grid
  grid-template repeat(6, auto) \/ 1fr
  grid-template-areas 'name  '\
                      'day   '\
                      'month '\
                      'year  '\
                      'button'\
                      'delete'
  gap 10px 10px

  +pc()
    grid-template repeat(4, auto) \/ auto 1fr auto
    grid-template-areas 'name   name   .     '\
                        'day    month  year  '\
                        'button button button'\
                        'delete delete delete'
    row-gap 20px

.name
  grid-area name

.day
  grid-area day

.month
  grid-area month

.year
  grid-area year

.button
  grid-area button
  margin-bottom -10px

  +pc()
    margin-bottom -20px

.deleteWrap
  grid-area delete
  text-align center
  margin-top 20px

  +pc()
    margin-top 40px

.delete
  font-size 14px
  line-height 1.2
  color #C50202
  text-decoration none
