@import '../../../../styles/mixins.styl'

.path
  display grid
  grid-template repeat(3, auto) \/ 100%
  grid-template-areas 'content'\
                      'image  '\
                      'run    '
  gap 20px 0
  width 100%
  max-width 1100px

  +pc()
    grid-template repeat(2, auto) \/ 1fr auto 1fr
    grid-template-areas 'content image .   '\
                        'run     run   run '
    align-items center
    gap 35px 0

.che
  grid-area image
  display block
  max-width 211px
  height auto

  +pc()
    max-width 100%

.content
  grid-area content

.descr
  font-size 20px
  line-height 1.2
  margin-bottom 20px

  +pc()
    font-size 28px

.run
  grid-area run
  width 100%

.scroll
  display flex
  flex-direction column
  align-items flex-end
  margin-left -130%
  gap 30px 60px
  width max-content

  +pc()
    flex-direction row
    align-items center

.road
  max-width 1056px

  +pc()
    max-width 100%

.runDescr
  max-width calc(100vw - 40px)
  font-size 16px

  +pc()
    flex 1 1 368px
    max-width 368px
    font-size 18px


