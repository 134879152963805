@import '../../styles/vars.styl'

.header
  position fixed
  top 0
  left 0
  width 100%
  height 70px
  background #EDF2FF
  backdrop-filter blur(10px)
  z-index 9999

.inner
  display flex
  align-items center
  column-gap 30px
  max-width 1200px
  width 100%
  height 100%
  box-sizing border-box
  padding 0 $padding
  margin 0 auto

.logoLink
  cursor pointer

.logo
  display block
  max-width 100%
  height auto

.navLink
  font-weight 700
  font-size 14px
  line-height 1.2
  color inherit
  text-decoration none
  text-align center
  cursor pointer

.button
  margin-left auto

.dropdownWrap
  position relative
  margin-left auto

.dropdown
  position absolute
  display flex
  flex-direction column
  align-items center
  row-gap 34px
  top 100%
  right 19px
  width 180px
  background #EDF2FF
  border-radius 24px 0 24px 24px
  filter drop-shadow(0 0 20px rgba(0, 0, 0, 0.4))
  text-align center
  box-sizing border-box
  margin-top 22px
  padding 24px

  &::before
    position absolute
    content ''
    bottom 100%
    right 0
    width 14px
    height 14px
    background-image url('./images/corner.svg')
    background-repeat no-repeat

.avatar
  display block
  border-radius 50%
  overflow hidden
  cursor pointer

.dropdownLink
  font-weight 700
  font-size 13px
  line-height 1.2
  color inherit
  text-decoration none
