@import '../../styles/mixins.styl'

.checkbox
  display inline-flex
  align-items center
  column-gap 10px

  +pc()
    column-gap 20px

.input
  position absolute
  opacity 0
  z-index -1

.icon
  flex 0 0 24px
  width 24px
  height 24px
  background-color #FFFFFF
  border-radius 8px
  cursor pointer

  +pc()
    flex 0 0 32px
    width 32px
    height 32px

.input:checked + .icon
  background-color #0066FF
  background-image url('./images/icon.svg')
  background-repeat no-repeat
  background-size 13px 9px
  background-position center

  +pc()
    background-size 17px 11px

.text
  flex 1 1 100%
