@import '../../../../../styles/mixins.styl'

.editButton
  position absolute
  top 24px
  right 28px
  background url('./images/icon.svg')
  background-repeat no-repeat
  width 24px
  height 24px

  +pc()
    position initial

.user
  +pc()
    margin-left auto

.child
  +pc()
    margin-left 20px
