@import '../../styles/mixins.styl'

.register
  display flex
  flex-direction column
  padding-top 20px

  +pc()
    flex-direction row
    align-items stretch
    padding-top 0

.form
  display flex
  flex-direction column
  align-items center
  text-align center
  margin-bottom 30px

  +pc()
    flex 1 1 100%
    border-right 1px solid #878998
    padding-right 40px
    margin-bottom 0

.field
  & + .field
    margin-top 10px

.comment
  font-size 14px
  line-height 1.2
  margin-top 10px

.button
  margin-top 40px

.title
  font-size inherit
  font-weight inherit
  text-align center
  margin 0 0 30px

.services
  +pc()
    flex 0 1 520px
    padding-left 40px

.service
  & + .service
    margin-top 10px

.service
  flex-direction row
  justify-content flex-start
  column-gap 10px
  padding 0 34px 0 10px

.serviceIcon
  flex 0 0 auto

.serviceName
  flex 1 1 100%
  text-align center

.agreement
  font-size 14px
  text-align center
  margin-top 40px

  +pc()
    font-size 16px

.link
  text-decoration underline
  color #0066FF

