@import '../../styles/mixins.styl'

.popup
  z-index 99999
  position absolute
  width 100%
  height 100%
  background-color rgba(0,0,0,0.5)

.feedbackWindow
  overflow hidden !important
  padding 50px 10px 50px 10px
  position absolute
  background-color #edf2ff
  width 700px
  left calc(50% - 350px)
  top 20%
  height fit-content
  border-radius 25px
  z-index 99999
  +mobile()
    padding 50px 0 50px 0
    width 100%
    font-size 18px
    height fit-content
    left 0

    border-radius 40px

.field
  margin-top 5px

.navLink
  position absolute
  right 25px
  top 25px
  font-weight 700
  font-size 20px
  line-height 1.2
  color inherit
  text-decoration none
  text-align center
  cursor pointer


