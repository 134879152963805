.input
  flex 1 1 100%
  font-family inherit
  font-weight inherit
  font-size inherit
  line-height inherit
  color inherit
  width 100%
  height 100%
  background none
  border 0
  outline none
  padding 0

input:-webkit-autofill{
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px white;
}

.input:disabled
  color inherit
  opacity 1

.right
  text-align left
