@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

.compete
  position relative
  width 100%
  max-width $max_width
  will-change transform

.che
  position absolute
  top 200px
  left -20px
  max-width 274px
  will-change transform
  z-index 10

  +pc()
    top 100px
    left 300px
    max-width 100%

.graph
  position relative
  transform translateX(-60%) scale(0.75)
  margin -70px 0

  +pc()
    transform none
    margin 0

.star
  position absolute
  top 200px
  right 0
  max-width 46px
  z-index 10

  +pc()
    top 150px
    max-width 100%

.starIcon
  position absolute
  bottom 10px
  left 310px
  z-index 10

.crownIcon
  position absolute
  bottom 175px
  left 305px
  z-index 10

.cupIcon
  position absolute
  bottom 90px
  left 310px
  z-index 10

.content
  will-change transform
  z-index 10

  +pc()
    position absolute
    top 480px
    right 0
    width 55%

.title
  margin 0 0 20px

.descr
  font-size 20px

  +pc()
    font-size 28px

