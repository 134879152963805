@import '../../../../styles/mixins.styl'

.row
  position relative
  display flex
  flex-direction column
  gap 10px 20px
  background #C3D4F3
  border-radius 20px
  box-sizing border-box
  padding-top 24px
  padding-bottom 24px
  padding-left 20px
  padding-right 20px

  +pc()
    flex-direction row
    align-items center
    height 60px
    border-radius 30px
    padding-top 18px
    padding-bottom 18px
    padding-left 0
    padding-right 28px

  & + .row
    margin-top 10px

.user
  +pc()
    padding-left 28px
