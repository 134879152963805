@import '../../../../../styles/mixins.styl'

.button
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 28px
  height 28px
  border 2px solid #0066FF
  border-radius 50%
  margin-left auto

  +pc()
    width 32px
    height 32px
