@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

.sectionMethodology
  justify-content flex-start
  padding-bottom 115px

  +pc()
    justify-content center
    padding-bottom 0

.methodology
  display flex
  flex-direction column
  gap 60px 40px
  width 100%
  max-width $max_width

  +pc()
    flex-direction row
    align-items stretch

.step
  will-change transform

  +pc()
    flex 1 1 100%
