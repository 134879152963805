@import '../../styles/mixins.styl'

.auth
  display flex
  flex-direction column
  padding-top 20px

  +pc()
    flex-direction row
    align-items stretch
    padding-top 0

.form
  display flex
  flex-direction column
  align-items center
  text-align center
  margin-bottom 30px

  +pc()
    flex 1 1 100%
    border-right 1px solid #878998
    padding-right 40px
    margin-bottom 0

.field
  & + .field
    margin-top 10px

.button
  margin-top 20px

.resetPassword
  font-size 14px
  line-height 1.2
  text-align center
  color #0066FF
  text-decoration none
  margin-top 30px

.register
  display flex
  flex-direction column
  align-items center

  +pc()
    flex 0 1 520px
    justify-content center
    padding-left 40px

.title
  font-weight 900
  font-size 24px
  line-height 1.2
  text-align center
  letter-spacing -0.05em
  color: #000000
  margin 0 0 30px

.services
  display flex
  width 100%
  column-gap 20px
  margin-bottom 30px

.service
  text-decoration none
