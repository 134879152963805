@import '../../styles/vars.styl';
@import '../../styles/mixins.styl';

.footer
  display grid
  grid-template repeat(3, auto)\/1fr auto
  grid-template-areas 'socials   socials   '\
                      'nav       age-rating'\
                      'copyright age-rating'
  align-items center
  gap 20px
  max-width 1200px
  width 100%
  box-sizing border-box
  padding 0 $padding 40px
  margin 100px auto 0

  +pc()
    padding-bottom 60px
    margin-top 200px

.socials
  grid-area socials
  display flex
  align-items center
  justify-content center
  column-gap 40px
  border-bottom 1px solid rgba(#7C859E, 0.5)
  padding-bottom 30px

  +pc()
    justify-content flex-end

.nav
  grid-area nav
  display flex
  flex-direction column
  gap 10px 24px

  +pc()
    flex-direction row

.navLink
  font-size 12px
  line-height 1.1
  color #7C859E
  text-decoration none

.copyright
  grid-area copyright
  font-size 12px
  line-height 1.1
  color #7C859E

.ageRating
  grid-area age-rating

.ageRatingValue
  display flex
  flex-direction column
  text-align center
  justify-content center
  width 42px
  height 42px
  background rgba(#7C859E, 0.5)
  border-radius 50%
  font-size 16px
  line-height 1
  color #EDF2FF
