@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

.interests
  display flex
  flex-direction column
  gap 40px 52px
  width 100%
  max-width $max_width
  +retina()
    margin-top 50px

  +pc()
    flex-direction row
    align-items stretch

.content
  flex 1 1 100%
  display flex
  flex-direction column

.graph
  will-change transform

  +pc()
    flex 1 1 100%
    align-self flex-end
    padding-right 23px

.title
  order 2
  margin-bottom 20px

  +pc()
    order 1

.descr
  font-size 20px
  line-height 1.2
  order 3

  +pc()
    font-size 28px
    order 2

.che
  display block
  max-width 260px
  height auto
  order 1
  margin-bottom 20px

  +pc()
    max-width 100%
    order 3
    margin-top -10px
    margin-bottom 0

.progress
  position relative
  display flex
  align-items center
  column-gap 10px
  min-width 46px
  height 28px
  will-change width
  background #38D8BB
  border-radius 23px
  box-sizing border-box
  padding 1px

  +pc()
    height 46px
    padding 3px

.progress + .progress
  margin-top 9px

.history
  background #38D8BB

.space
  background #8841DA

.science
  background #E2B542

.environment
  background #25AD20

.nature
  background #DE419F

.sport
  background #159EC9

.creativity
  background #D34859

.technology
  background #D0D237

.human
  background #6AC9E7

.icon
  width 26px
  height 26px

  +pc()
    width 40px
    height 40px

.label
  flex 1 1 auto
  font-weight 900
  font-size 14px
  line-height 1.9
  letter-spacing -0.02em
  color #FFFFFF
  text-shadow 1px 0 rgba(0, 0, 0, 0.3), 0 1px rgba(0, 0, 0, 0.3), -1px 0 rgba(0, 0, 0, 0.3), 0 -1px rgba(0, 0, 0, 0.3)
  overflow-x hidden
  white-space nowrap
  will-change opacity

  +pc()
    font-size 20px
