@import '../../styles/mixins.styl'
.button
  padding 0 10px

.profit
  background-color #61B44A
  width fit-content
  padding 5px
  margin-top -15px
  color white
  border-radius 3px

.image
  position relative
  left auto
  right  -50%
  width 27px
  +mobile()
    right -60%
    left auto



