@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

.arts
  display flex
  flex-direction column
  gap 20px 40px
  width 100%
  max-width 838px

  +pc()
    flex-direction row
    align-items center

.content
  will-change transform

.title
  margin-bottom 20px

.descr
  font-size 18px
  line-height 1.2
  margin 0 -80px 20px 0

  +pc()
    font-size 28px

.link
  display inline-flex
  align-items center
  column-gap 10px
  text-decoration none
  line-height 0.75
  color $link_color

  &::after
    content ''
    flex 0 0 15px
    background-image url('images/arrow.svg')
    background-repeat no-repeat
    width 15px
    height 14px

.che
  display block
  max-width 224px
  height auto

  +pc()
    max-width 100%
