@import '../../../../styles/mixins.styl'

.pageHead
  display flex
  flex-direction column
  gap 30px
  padding 60px 0 30px

  +pc()
    flex-direction row
    align-items center
    justify-content space-between
    padding 80px 0 62px

.title
  font-weight 900
  font-size 40px
  line-height 1.1
  letter-spacing -0.04em
  margin 0

  +pc()
    font-size 50px

.button
  flex-direction row
  column-gap 10px
  font-weight 900
  font-size 16px
  height 60px
  padding 0 20px
