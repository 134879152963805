@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

.lessons
  position relative
  width 100%
  height 100%
  max-width 1400px
  z-index 9999
.wrap

  display flex
  flex-direction column
  justify-content center
  gap 10px 58px
  max-width 1100px
  height 100%
  margin 0 auto

  +pc()
    flex-direction row
    align-items center
    justify-content flex-start

.che
  display block
  max-width 267px
  height auto
  margin-top -50px
  margin-right 50px
  +pc()
    max-width 100%

.content
  position relative
  //background-color rgba(255,255,255,0.75)
  border-radius 25px
  padding 25px
  will-change transform
  z-index 99999

  +pc()
    display flex

    justify-content center

.title
  margin-bottom 20px

.descr
  font-size 18px
  margin-bottom 40px

  +pc()
    font-size 24px

.images
  opacity 0.5
  position absolute
  display flex
  justify-content center
  align-items flex-end
  column-gap 1.4%
  top 0
  left 0
  width 100%
  height 100%
  transform-origin top center
  box-sizing border-box
  pointer-events none
  z-index -1

  +pc()
    padding 0 $padding

.imagesMobile
  margin-top 20px

.col
  flex 1 1 100%
  width 100%
  max-width 100%
  height max-content
  will-change transform
