@import '../../../../styles/vars.styl';
@import '../../../../styles/mixins.styl';

.info
  background #8AFFA4

.error
  background #C50202

.container
  display flex
  flex-direction column
  gap 10px 30px
  max-width $max_width
  margin 0 auto
  padding 20px $padding

  +pc()
    flex-direction row
    align-items flex-start

