@import './vars.styl'
@import './fonts.styl'
@import './mixins.styl'

html
  font-family 'Craftwork Grotesk', sans-serif
  font-weight 500
  font-size 14px
  line-height 1.3
  color $text_color
  height 100%

  +pc()
    font-size 18px

body
  background #EDF2FF
  height 100%
  margin 0

a
  color $link_color

  &:hover
    text-decoration none

b
  font-weight 900

:global
  #root
    height 100%

  .page-title
    margin-top 60px !important
    margin-bottom 30px !important
