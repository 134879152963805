@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

:global
  #subscribe
    scroll-margin-top 90px

    +pc()
      scroll-margin-top 0

.subscribe
  text-align center
  max-width $max_width
  width 100%
  padding-bottom 130px

  +pc()
    text-align left
    padding-bottom 0

.title
  margin-bottom 60px

.list
  display grid
  grid-template repeat(3, auto) \/ 1fr
  gap 10px 20px
  text-align left
  margin-bottom 40px

  +pc()
    grid-template 1fr \/ repeat(3, 1fr)
    row-gap 0

.tariff
  flex 0 1 33.333%
  will-change transform

.image
  will-change opacity

.logo
  display inline-block
  max-width 100%
  height auto

.recurrent
  margin 30px 0