@import '../../../styles/mixins.styl'

.title
  width 100%
  font-weight 900
  font-size 13px
  line-height 1.2
  letter-spacing -0.05em
  margin 0 0 -10px

  +mobile()
    font-size 13px

.special
  color #E6FF4E
