@font-face
  font-family 'Craftwork Grotesk'
  font-style normal
  font-weight 400
  font-display swap
  src url('./fonts/CraftworkGrotesk-Regular.woff2') format('woff2')

@font-face
  font-family 'Craftwork Grotesk'
  font-style normal
  font-weight 500
  font-display swap
  src url('./fonts/CraftworkGrotesk-Medium.woff2') format('woff2')

@font-face
  font-family 'Craftwork Grotesk'
  font-style normal
  font-weight 600
  font-display swap
  src url('./fonts/CraftworkGrotesk-SemiBold.woff2') format('woff2')

@font-face
  font-family 'Craftwork Grotesk'
  font-style normal
  font-weight 700
  font-display swap
  src url('./fonts/CraftworkGrotesk-Bold.woff2') format('woff2')

@font-face
  font-family 'Craftwork Grotesk'
  font-style normal
  font-weight 900
  font-display swap
  src url('./fonts/CraftworkGrotesk-Heavy.woff2') format('woff2')
