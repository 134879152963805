@import '../../styles/mixins.styl'

.promocode
  display grid
  grid-template repeat(3, auto) \/ 1fr
  grid-template-areas 'title '\
                      'input '\
                      'button'
  gap 20px 10px
  border-top 1px solid rgba(#080431, 0.2)
  padding-top 30px

  +pc()
    grid-template repeat(2, auto) \/ minmax(auto, 400px) auto 1fr
    grid-template-areas 'title title  title'\
                        'input button .    '

.title
  grid-area title
  font-weight 900
  font-siz 24px
  line-height 1.2
  letter-spacing -0.05em
  margin 0

.input
  grid-area input

.successInput {
  background #E6FF4E
}

.button
  grid-area button

.successMessage
  grid-area button
  align-self center
  display flex
  align-items center
  column-gap 10px
  margin-left 10px

  &::before
    content ''
    width 21px
    height 16px
    background-image url('./images/icon.svg')
    background-repeat no-repeat
