@import '../../../../styles/vars.styl'

.section
  display flex
  flex-direction column
  justify-content center
  align-items center
  box-sizing border-box
  padding 40px $padding

.fullHeight
  height 100%
  padding-top 0
  padding-bottom 0
