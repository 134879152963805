@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'
.content__wrapper
  overflow hidden
  position absolute
  z-index 99999
  width 890px
  height 538px
  border:10px solid white
  background-color white
  background-image url('./images/promo_bg.png');
  background-repeat no-repeat
  border-radius 38px
  bottom 20px
  box-shadow 2px 2px 5px 0px rgba(0, 0, 0, 0.5)
  left calc(50% - 445px)
  top calc(50% - 269px)
  font-size  60px
  font-weight 900

  +mobile()
    width 90%
    height 590px
    top calc(50% - 295px)
    left 50%
    transform translateX(-50%) scale(1)
    font-size 25px
    border-radius 38px
.wrapper
  z-index 99999
  position absolute
  width 100%
  height 100%
  background-color rgba(0,0,0,0.5)

.content
  cursor default
  padding-left 100px
  display grid
  grid-template-columns: 1fr 1fr;
  +mobile()
    display grid
    grid-template-columns: 1fr;
    padding-left 50px

.sale__block
  background-image url('./images/Subtract.png')
  padding-top 20px
  margin-top 20px
  background-size: contain;
  background-repeat no-repeat
  height 175px
  width 308px
  align-items center
  font-size 30px
  text-align center
  span
    font-size 90px

  +mobile()
    font-size 15px
    height 90px
    width 150px
    span
      font-size 30px




.decline
  width 17px
  cursor pointer
  position absolute
  right 5%
  top 5%

.del
    font-size: 17px;
    position: relative;
    color: #000;
    text-decoration: none;

.del:before
  content: "";
  border-bottom: 3px solid red;
  position: absolute;
  width: 100%;
  height: 50%;
  transform: rotate(-12deg);

.list
  font-size 16px
  font-weight 400
  line-height 17px
  margin-top 20px


.button
  position absolute
  cursor pointer
  margin-left -50px
  font-size 18px
  background-image url('./images/background.png');
  background-repeat no-repeat
  width 346px
  height 92px
  border-radius 36px
  z-index 99990
  line-height 62px
  display flex
  +mobile()
    bottom 30px
    left calc(50% - 120px)



.buttonInvisible
  position absolute
  cursor pointer
  margin-left -50px
  font-size 18px

  background-repeat no-repeat
  width 346px
  height 92px
  border-radius 36px
  z-index 99999
  line-height 62px
  display flex
  +mobile()
    bottom 30px
    left calc(50% - 120px)

.infoBlock
  margin-left 50px
  +mobile()
   margin-left 0

.hand
  position: absolute
  bottom:-10px
  right:-10px
  z-index:99998
  +mobile()
   bottom -60px
   transform scale(0.5)
   right -150px

.closeButton
  cursor pointer
  position absolute
  right 30px
  top 15px
  font-size 30px
  +mobile()
    font-size 18px


