@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

.promo
  background-image: url("./images/Vector11.svg");
  top 50px
  position relative
  height 200%
  overflow hidden
  +pc()
    height 100%
.promo__block__wrapper

  z-index 99999
  justify-content center
  margin-top 45px
  +pc()
    display flex

.promo__block
  border-radius 25px
  padding 40px
  width: 87%
  margin-top 5px
  height: fit-content
  line-height: 30px
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5)
  background-color: #ffffff
  text-align: center
  font-size: 46px
  +pc()
    width: fit-content
    height: fit-content
.promo__block__center
  z-index 99999
  border-radius 25px
  padding 40px
  width: 87%
  height: fit-content
  margin-top 5px
  line-height: 30px
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5)
  background-color: #ffffff
  text-align: center
  font-size: 46px
  +pc()
    width: fit-content
    height: fit-content
    margin-top: 15px
    margin-left: -15px
    margin-right: -15px

.map
  width auto
  height 100%
  will-change transform

  +mobile()
    transform translate(-400px, 170px) scale(1.5)
    transform-origin left bottom

.container
  position relative
  top 0
  left 50%
  max-width 1400px
  width 100%
  height 100%
  transform translateX(-50%)
  display grid
  grid-template 0.3fr auto 1fr \/ 1fr
  grid-template-areas '.       '\
                      'content '\
                      'button  '
  justify-items center
  gap 30px
  box-sizing border-box
  padding 0 $padding

  +mobile()
    &::before
      position absolute
      content ''
      top 0
      left 0
      width 100%
      height 100%

      opacity 0.2
      z-index -1

.content
  align-self center
  grid-area content
  color #080431;

  text-align center
  font-size 25px
  font-weight 900
  line-height 1.1
  +pc()
    font-size 50px



.image
  max-width 335px
  z-index 9999

  margin-bottom 150px
  +pc()
    margin-left -335px
    width 335px


.buttonWrap
  grid-area button

  +mobile()
    display flex
    align-items flex-end
    width 100%
    padding-bottom 100px

.button
  +mobile()
    width 100%
    height 92px
    font-size 22px
    font-weight 900
    padding 35px 10px
