.price
  grid-area price
  display flex
  font-weight 900
  font-size 40px
  line-height 1

.special
  color #E6FF4E

.specialSecond
  color #FFCE00

.specialThird
  color #FFE767

.specialFour
  color black

.currency
  font-size 20px
  line-height 1
