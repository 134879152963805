@import '../../../../styles/mixins.styl';

.banner
  display grid
  grid-template repeat(6, auto) \/ 1fr
  grid-template-areas 'subtitle '\
                      'title    '\
                      'old-price'\
                      'price    '\
                      'comment  '\
                      'actions  '
  gap 10px
  border-radius 30px
  padding 30px

  margin-bottom 20px

  +pc()
    grid-template repeat(3, auto) \/ repeat(3, max-content) 1fr
    align-items center
    grid-template-areas 'subtitle subtitle  subtitle  subtitle'\
                        'title    old-price price     actions '\
                        'comment  comment   comment   comment '
    border-radius 60px
    padding 40px

.special
  background #EA00E0
  color #FFFFFF

.specialSecond
  background #D477B2
  color #FFFFFF

.specialThird
  background #D06D10
  color #FFFFFF

.specialFour
  background #FFFFFF
  color black
