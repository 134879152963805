@import '../../../../styles/mixins.styl'

.section
  background #D8DFF5
  border-radius 20px
  box-sizing border-box
  padding 20px
  margin 0 auto

  +pc()
    border-radius 60px
    padding 40px

  & + .section
    margin-top 20px
