@import '../../../../../styles/mixins.styl'

.birthday
  font-weight 900
  font-size 16px
  line-height 1.2
  letter-spacing -0.05em

  +pc()
    font-size 20px
    margin-left auto
