@import '../../../../styles/mixins.styl'

.universal
  width 100%
  max-width 1100px
  padding-bottom 120px
  +retina()
    margin-top 80px
  +pc()
    padding-bottom 0

.title
  text-align center
  margin-bottom 20px

.subtitle
  text-align center
  font-size 20px
  line-height 1.2
  margin-bottom 20px

  +pc()
    font-size 28px

.circles
  display flex
  flex-direction column
  align-items center
  will-change transform

  +pc()
    flex-direction row

.circle
  display flex
  flex-direction column
  align-items center
  justify-content center
  border-radius 50%
  text-align center
  will-change transform
  box-sizing border-box

  +pc()
    flex 0 0 580px
    justify-content flex-end
    padding-bottom 80px

.pc
  width 342px
  height 342px
  background rgba(#F563FF, 0.5)
  margin-bottom -20px

  +pc()
    width 580px
    height 580px
    margin-right -40px
    margin-bottom 0

.mobile
  width 460px
  height 460px
  background rgba(#00FFFF, 0.5)

  +pc()
    width 580px
    height 580px
    margin-left -40px

  & .sectionTitle
    max-width 300px

    +pc()
      max-width 100%

.che
  display block
  height auto
  margin-bottom 16px

.chePc
  max-width 151px

  +pc()
    max-width 100%

.cheMobile
  max-width 114px

  +pc()
    max-width 100%

.sectionTitle
  font-weight 900
  font-size 28px
  line-height 1.1
  text-align center
  letter-spacing -0.05em
  margin 0 0 10px

.descr
  font-size 16px
  margin-bottom 30px

  +pc()
    font-size 18px

.markets
  display flex
  flex-direction column
  gap 10px

  +pc()
    flex-direction row

.link
  flex 0 0 auto

.image
  display block

.button
  display none

  +pc()
    display inline-flex
