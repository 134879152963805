@import '@styles/vars.styl';
@import '@styles/mixins.styl';

.page
  display flex
  flex-direction column
  align-items center
  height 100%
  box-sizing border-box
  padding-top 44px

  +pc()
    padding-top 70px

.container
  flex 1 1 100%
  width 100%
