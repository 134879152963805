@import '../../../../../styles/mixins.styl';

.title
  grid-area title
  font-weight 900
  font-size 24px
  line-height 1.1
  margin 0

  +pc()
    font-size 40px

.special
  color #E6FF4E

.specialSecond
  color #FFCE00

.specialThird
  color #FFE767

.specialFour
  color black
