@import '../../../styles/mixins.styl';

.oldPrice
  width 100%
  font-weight 900
  margin-left 10px
  font-size 15px
  color #CBCBCB;

  line-height 2
  letter-spacing -0.05em
  margin-bottom 25px

  +pc()
    margin-bottom 50px

.special
  color $text_color
  margin-bottom 0

  & .cross
    &::after
      width 75px
      height 46px
      background-image url('./images/cross-grey.svg')

.cross
  position relative

  &::after
    position absolute
    content ''
    top 50%
    left 50%
    width 69px
    height 40px
    background-image url('./images/cross-grey.svg')
    background-repeat no-repeat
    background-size contain
    transform translate(-50%, -50%)
    z-index 10
