@import '../../../styles/mixins.styl'

.descr
  font-weight 500
  font-size 16px
  line-height 1.2
  text-align center
  margin-top 10px

  +pc()
    font-size 24px
