@import '@styles/vars.styl'

.oldPrice
  grid-area old-price
  font-weight 900
  font-size 40px
  line-height 1

.special
  color $text_color

.specialSecond
  color $text_color

.specialThird
  color $text_color

.specialFour
  color rgba(0,0,0,0.6)

.cross
  position relative
  display inline-flex

  &::after
    position absolute
    content ''
    top 50%
    left 50%
    width 135px
    height 42px
    background-image url('./images/cross.svg')
    background-size contain
    background-repeat no-repeat
    transform translate(-50%, -50%)
    z-index 10

.currency
  font-size 20px
  line-height 1
