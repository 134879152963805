@import '../../styles/mixins.styl'

.button
  display inline-flex
  flex-direction column
  justify-content center
  align-items center
  text-align center
  font-family inherit
  line-height 1.1
  border 0
  outline none
  color inherit
  text-decoration none
  background transparent
  border-radius 40px
  box-sizing border-box
  cursor pointer
  white-space nowrap

.green
  background #8AFFA4

.blue
  background #0066FF
  color #FFFFFF

.yellow
  background #FFC700

.red
  background #C50202
  color #FFFFFF

.blueBorder
  border 2px solid #0066FF
  color #0066FF

.black
  background #080431
  color #FFFFFF

.grayBlue
  background: #6F81CC;

.large
  font-size 28px
  font-weight 900
  height 80px
  border-radius 40px
  padding 0 56px

.normal
  font-size 16px
  font-weight 700
  height 40px
  border-radius 30px
  padding 0 47px

  +pc()
    font-size 18px
    height 60px

.small
  font-size 16px
  font-weight 700
  height 40px
  border-radius 24px
  padding 0 54px

  +pc()
    font-size 18px
    height 48px

.very-small
  font-size 13px
  font-weight 700
  height 40px
  border-radius 20px
  padding 0 20px

.fullWidth
  width 100%
