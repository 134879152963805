@import '../../../../styles/mixins.styl'

.deleteChild
  display flex
  flex-direction column
  gap 10px 20px

  +pc()
    flex-direction row

.button
  +pc()
    flex 1 1 100%
