@import '../../styles/vars.styl'
@import '../../styles/mixins.styl'

.demo
  max-width $max_width

.default
  padding 0 $padding
  margin 0 auto

  & .inner
    padding 40px 48px

    +pc()
      padding 60px 100px

.popup
  & .inner
    row-gap 20px

    +pc()
      row-gap 42px

  & .che
    max-width 100px

    +pc()
      max-width 100%

.inner
  display grid
  grid-template repeat(4, auto)\/1fr
  grid-template-areas 'title   '\
                      'subtitle'\
                      'che     '\
                      'trial   '\
                      'free    '\
                      'button  '
  justify-content center
  gap 42px 33px
  background #8AFFA4
  border-radius 20px

  +pc()
    grid-template repeat(4, auto)\/1fr auto 1fr
    grid-template-areas 'title    title    title   '\
                        'subtitle subtitle subtitle'\
                        'trial    che      free    '\
                        'button   button   button  '
    align-items center
    gap 42px 33px
    border-radius 40px

.title
  grid-area title
  text-align center
  margin-bottom -13px

.subtitle
  grid-area subtitle
  text-align center
  font-size 20px
  line-height 1.2

  +pc()
    font-size 28px
    margin-bottom 70px

.trial
  grid-area trial
  text-align center

.che
  grid-area che
  display block
  max-width 100%
  height auto
  margin 0 auto

  +pc()
    margin 0

.free
  grid-area free
  text-align center

.button
  grid-area button
  text-align center

.sectionTitle
  font-weight 900
  font-size 20px
  line-height 1.1
  letter-spacing -0.05em
  margin 0 0 10px

  +pc()
    font-size 28px

.descr
  font-size 16px
