@import '../../../styles/mixins.styl'

.special
  color #E6FF4E

.promocode
  background #E6FF4E
  margin 0 -16px
  padding 12px 16px

.title
  font-weight 900
  font-size 18px
  line-height 1.2
  letter-spacing -0.05em
  margin-bottom 10px

  +pc()
    font-size 16px

.value
  display flex
  width 100%
  font-weight 900
  font-size 32px
  line-height 1
  letter-spacing -0.05em

  +pc()
    font-size 38px

.currency
  font-size 20px
  line-height 1
