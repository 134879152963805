@import '../../styles/mixins.styl'

.tariff
  display flex
  flex-direction column
  align-items stretch
  row-gap 20px
  border-radius 20px
  height 100%
  box-sizing border-box
  padding 30px

  +pc()
    border-radius 40px
    padding 40px

.default
  background #FFFFFF

.current
  background #C3D4F3

.special
  background #EA00E0
  color #FFFFFF


.image
  position relative
  left 0
  width 36px
  +mobile()
    right 50px
    left auto
