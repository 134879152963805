.header
  position fixed
  top 0
  left 0
  width 100%
  height 44px
  display flex
  align-items center
  column-gap 20px
  background #FFFFFF
  box-sizing border-box
  padding-left 10px
  padding-right 20px
  z-index 9999

.toggleNavButton
  background none
  border 0
  outline none
  cursor pointer
  padding 0

  &.isLogin
    margin-left auto

.nav
  display flex
  flex-direction column
  position fixed
  top 44px
  left 0
  width 100%
  background #FFFFFF
  backdrop-filter blur(10px)
  box-shadow 0 0 20px rgba(0, 0, 0, 0.05)
  transform translateY(-100%)
  will-change transform
  transition 0.15s linear
  box-sizing border-box
  padding 20px 0
  z-index 9998

  &.open
    transform translateY(0)

.logoLink
  cursor pointer

.navLink
  color inherit
  text-decoration none
  font-size 16px
  font-weight 900
  cursor pointer
  padding 20px

.button
  flex-grow 1
  height 28px
