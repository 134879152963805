.select
  font-family inherit
  font-weight inherit
  font-size inherit
  line-height inherit
  color inherit
  width 100%
  height 100%
  background-color transparent
  background-image url('./images/arrow.svg')
  background-repeat no-repeat
  background-position center right
  border 0
  outline none
  appearance none
  cursor pointer
  padding 0 20px 0 0
