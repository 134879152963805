@import '../../../../styles/vars.styl'
@import '../../../../styles/mixins.styl'

.verified
  display flex
  flex-direction column
  row-gap 20px
  width 100%
  max-width 761px

  +pc()
    flex-direction row
    align-items center

.content
  will-change transform
  order 2

  +pc()
    order 1

.title
  margin-bottom 20px

.descr
  font-size 18px
  line-height 1.2
  margin-bottom 20px

  +pc()
    font-size 28px
    margin 0 -80px 20px 0

.link
  display inline-flex
  align-items center
  column-gap 10px
  text-decoration none
  line-height 0.75
  color $link_color

  &::after
    content ''
    flex 0 0 15px
    background-image url('images/arrow.svg')
    background-repeat no-repeat
    width 15px
    height 14px

.cheWrap
  order 1

  +pc()
    order 2

.che
  display block
  max-width 157px
  height auto

  +pc()
    max-width 100%
