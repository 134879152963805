@import '../../../../styles/mixins.styl'

.publications
  max-width 1240px
  width 100%
  +retina()
   margin-top 120px

.title
  text-align center
  margin-bottom 20px

.subtitle
  font-size 20px
  line-height 1.2
  text-align center
  margin-bottom 68px

  +pc()
    font-size 28px

.list
  display grid
  grid-template 1fr \/ 1fr
  grid-auto-rows 1fr
  gap 20px

  +pc()
    grid-template 1fr \/ repeat(3, 1fr)

.article
  position relative
  background #FFFFFF
  border-radius 40px
  text-decoration none
  color inherit
  will-change transform
  padding 40px 40px 90px

.text
  font-size 16px
  margin-bottom 43px

  +pc()
    font-size 20px

.logo
  position absolute
  bottom 40px
  left 40px
