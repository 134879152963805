@import '../../styles/mixins.styl'

.field
  position relative
  display flex
  align-items center
  column-gap 20px
  width 100%
  height 40px
  background #FFFFFF
  border-radius 20px
  box-sizing border-box
  padding 0 20px 0

  +pc()
    height 48px

.error
  border 2px solid #C50202
  margin-bottom 45px

.errorMessage
  position absolute
  top 100%
  left 0
  width 100%
  padding-left 20px
  padding-right 20px
