@import '../../styles/mixins.styl'

.title
  font-weight 900
  letter-spacing -0.04em
  margin-top 0
  margin-bottom 0
  margin-left 0
  margin-right 0

.normal
  font-size 32px
  line-height 1.1

  +pc()
    font-size 50px

.small
  font-size 24px
  line-height 1.2

  +pc()
    font-size 32px

