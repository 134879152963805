.error
  display flex
  align-items center
  column-gap 10px
  font-weight 700
  font-size 14px
  line-height 1.2
  color #C50202
  box-sizing border-box
  padding-top 10px
  padding-bottom 10px

  &::before
    flex 0 0 18px
    content ''
    width 18px
    height 16px
    background-image url('./images/icon.svg')
    background-repeat no-repeat

.right
  justify-content flex-end
  text-align right

.left
  justify-content flex-start
  text-align left
