@import '../../styles/mixins.styl'

.popup
  position fixed
  top 0
  left 0
  width 100vw
  height 100%
  transition 0.15s linear
  z-index 10000

.inner
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  width 100%
  background #EDF2FF
  border-radius 30px
  box-sizing border-box
  box-shadow 0 0 20px rgba(0, 0, 0, 0.05)
  padding 20px
  z-index 20

  +pc()
    border-radius 60px
    padding 60px 80px

.default
  background #EDF2FF

.green
  background #8AFFA4

.small
  max-width 335px

  +pc()
    max-width 680px

.normal
  max-width 335px

  +pc()
    max-width 880px

.overlay
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background #080431
  opacity 0.2
  z-index 10

.closeBtn
  position absolute
  top 20px
  right 20px
  border 0
  outline none
  background none
  cursor pointer
  padding 0

  +pc()
    top 40px
    right 40px
