@import './vars.styl'

pc()
  @media screen and (min-width: $pc_breakpoint)
    {block}

mobile()
  @media screen and (max-width: $pc_breakpoint - 1)
    {block}


retina()
  @media  (-webkit-min-device-pixel-ratio: 2),(min-resolution: 2dppx)
    {block}
