@import '../../../../../styles/mixins.styl'

.step
  height 100%

.stepName
  font-weight 900
  font-size 120px
  letter-spacing -0.05em

.image
  position relative
  width 100%
  object-fit contain
  aspect-ratio 3/2
  margin -60px 0 20px
  z-index 10

.title
  font-weight 900
  font-size: 28px
  line-height 1.1
  letter-spacing: -0.05em
  margin 0 0 10px

.descr
  font-size 16px

  +pc()
    font-size 18px
