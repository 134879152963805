@import '../../../styles/mixins.styl'

.title
  font-weight 900
  font-size 24px
  line-height 1.1
  text-align center
  letter-spacing -0.05em
  padding 0 40px
  margin 0

  +pc()
    font-size 32px
