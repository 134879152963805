@import '../../../../styles/mixins.styl'

.block
  width 80%
  background-color white
  display grid
  padding 20px
  grid-template-columns: 95% 5%;
  border-radius 25px
  margin-top 25px
  cursor pointer
.blockTitle

.title
  position relative
  text-align center

  +pc()
    width max-content
    padding 0 110px

  &::before
  &::after
    position absolute
    content ''
    background-image url('images/laurel.svg')
    background-repeat no-repeat
    background-size 53px 124px
    width 53px
    height 124px

    +pc()
      top 0
      width 90px
      height 210px
      background-size 90px 210px

  &::before
    left 50%
    bottom calc(100% - 20px)
    transform translateX(-50%) scale(1, 1) rotate(90deg)

    +pc()
      left 0
      bottom initial
      transform none

  &::after
    right 50%
    top calc(100% - 20px)
    transform translateX(50%) scale(-1, -1) rotate(90deg)

    +pc()
      right 0
      top 0
      transform scale(-1, 1)

.small
  display inline-block
  font-weight 500
  font-size 20px
  line-height 1.2
  margin-top 20px

  +pc()
    font-size font-size 28px
