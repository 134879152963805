.forgotPassword
  text-align center

.field
  & + .field
    margin-top 10px

.comment
  font-size 14px
  line-height 1.2
  margin-top 20px

.button
  margin-top 40px
